/* eslint-disable */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store/reduxStore';
import { ToastContainer } from 'react-toastify';
const PageLoad = React.lazy(()=>import('./PageLoad'));
const App = React.lazy(()=>import('./App'));

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Suspense fallback={<React.Fragment/>} >
     <Provider store={store}>
        <PersistGate loading={<div></div>} persistor={persistor}>
          <BrowserRouter>
            <App />
            <PageLoad />
            <ToastContainer/>
          </BrowserRouter>
        </PersistGate>
      </Provider>
  </React.Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
