/* eslint-disable */
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { allReducers } from "./allReducers";
import {thunk} from "redux-thunk";

const persistConfig = {
    key: "ct_admin",
    storage
};

const reducers = combineReducers({
    allReducers: allReducers
})

// export default reducers;
const middleware = [thunk];


const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;

