/* eslint-disable */
import { ActionTypes } from "./Actions";

const initialData = {
    ModalPopup: {
        title: "",
        description:"",
        data: {},
        show: "",
        callBackModal: () => null,
        buttonSuccess: "",
        buttonCancel:""
    },
    device_id:"",
    currentUser:null,
    access_token:"",
    remember_me:{},
    categoryList: [],
    categoryListOption: [],
    magic_code:"",
    websocketReqRes: {
        request:null,
        msg:"",
        status:"",
        url:""
    },
    ModalPopup: {
        title: "",
        name:"",
        description:"",
        data: {},
        show: "",
        callBackModal: () => null,
        buttonSuccess: "",
        buttonCancel:""
    },
    coursedList:{
        data:[],
        pagination:{}
    },
    reviewList:{
        data:[],
        pagination:{}
    },
    testimonialList:{
        data:[],
        pagination:{}
    },
    coursesLevelList:[],
    packageList:[],
    usersDetails: null,
    faqList:[],
    blogList:{
        data:[],
        pagination:{},
    },
    salesHistory:{
        data:[],
        pagination:{},
    },
    privacyPolicyList:[],
    termsAndConditionsList:[],
    dashboardStat:{
        individual_users_count: "",
        business_register_count: "",
        total_users_count: "",
        total_courses_count: "",
    },
    heightAssignCourse:[],
    
};
const jsonParse = (data) =>{
    return data?.replaceAll("None", 'null').replaceAll("False", false).replaceAll("True", true)?.replaceAll(/'/g, '"')
}

export const allReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_CURRENT_USER:
            if(!action?.payload){
                return {
                    ...initialData,
                    remember_me: state?.remember_me,
                };
            }
            return{
                ...state,
                currentUser: action.payload,
                access_token: action?.payload?.access_token,
            }
        case ActionTypes.SET_DEVICE_ID:{
            return{
                ...state,
                device_id: action.payload,
            }
        }
        case ActionTypes.SET_STORE_REVIEW_LIST:{
            return{
                ...state,
                reviewList: action.payload,
            }
        }
        case ActionTypes.SET_STORE_REVIEW_UPDATE_LIST:{
            const newData = state?.reviewList?.data?.map((item)=>{
                if(item?.id == action?.payload?.id){
                    return {
                        ...item,
                        ...action?.payload
                    };
                }
                return{
                    ...item,
                }
            })
            return{
                ...state,
                reviewList:{
                    ...state?.reviewList,
                    data: newData
                },
            }
        }
        case ActionTypes.SET_STORE_UPDATE_TESTIMONIAL_LIST:{
            const newData = state?.testimonialList?.data?.map((item)=>{
                if(item?.id == action?.payload?.id){
                    return {
                        ...item,
                        ...action?.payload
                    };
                }
                return{
                    ...item,
                }
            })
            return{
                ...state,
                testimonialList:{
                    ...state?.testimonialList,
                    data: newData
                },
            }
        }
        case ActionTypes.SET_STORE_TESTIMONIAL_LIST:{
            const payloadData = action.payload?.data?.map((item)=>{
                const Data = jsonParse(item?.image)
                return{
                    ...item,
                    label: item?.category_name,
                    value: item?.id,
                    image: item?.icon === "None" ? null : JSON.parse(Data)
                }
            })
            return{
                ...state,
                testimonialList: {
                    data: payloadData,
                    pagination: action?.payload?.pagination,
                },
            }
        }
        case ActionTypes.SET_STORE_CATEGORY_LIST:{
            const payloadData = action.payload?.map((item)=>{
                const Data = item?.icon.replaceAll("None", 'null').replaceAll("False", false).replaceAll("True", true)?.replaceAll(/'/g, '"');
                return{
                    ...item,
                    label: item?.category_name,
                    value: item?.id,
                    icon: item?.icon === "None" ? null : JSON.parse(Data)
                }
            })
            return{
                ...state,
                categoryList: payloadData,
                categoryListOption: payloadData
            }
        }
        case ActionTypes.SET_STORE_MAGIC_CODE:{
            return{
                ...state,
                magic_code:action?.payload,
            }
        }
        case ActionTypes.SET_WEBSOCKET_REQ_RES:{
            return {
                ...state,
                websocketReqRes:{
                    request:action?.payload?.request || null,
                    msg:action?.payload?.msg || "",
                    status:action?.payload?.status || "",
                    url:action?.payload?.url || "",
                }
            }
        }
        case ActionTypes.SET_STORE_MODAL_CONFIRM:{
            // console.log("action?.payload",action?.payload)
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_MODAL_POPUP:
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        case ActionTypes.SET_STORE_COURSES_LIST:{
            const payloadData = action.payload?.data?.filter((item)=>item?.id)?.map((item)=>{
                    const course_image = jsonParse(item?.course_image);
                    const external_pdf = jsonParse(item?.external_pdf);
                    const course_video = jsonParse(item?.course_video);
                    const course_pdf = jsonParse(item?.course_pdf);
                    return{
                        ...item,
                        label: item?.course_name,
                        value: item?.id,
                        course_image: item?.course_image == null|| !item?.course_image || item?.course_image === "None" ? null : JSON.parse(course_image),
                        external_pdf: item?.external_pdf == null|| !item?.external_pdf || item?.external_pdf === "None" ? null : JSON.parse(external_pdf),
                        course_video: item?.course_video == null|| !item?.course_video || item?.course_video === "None" ? null : JSON.parse(course_video),
                        course_pdf: item?.course_pdf == null|| !item?.course_pdf || item?.course_pdf === "None" ? null : JSON.parse(course_pdf),
                    }
            });
            // console.log("payloadData", payloadData, state?.coursedList, action.payload?.data)
            return{
                ...state,
                coursedList: {
                    data: payloadData,
                    pagination: action.payload?.pagination
                },
            }
        }
        case ActionTypes.SET_STORE_COURSE_UPDATE_LIST:{
            // console.log("action.payload", action.payload);
            const newData = state?.coursedList?.data?.map((item)=>{
                const checkData = action.payload?.course_ids?.find(item1=>item1 == item?.id);
                if(checkData){
                    if(action?.payload?.star!=undefined){
                        item.is_star = action?.payload?.star;
                    }
                    if(action?.payload?.status!=undefined){
                        item.is_active = action?.payload?.status;
                    }
                }
                return{
                    ...item,
                }
            })
            return{
                ...state,
                coursedList:{
                    ...state?.coursedList,
                    data: newData
                },
            }
        }
        case ActionTypes.SET_STORE_COURSE_DETAILS:{
            const courseDetails = action?.payload;
            if(action?.payload){
                    const course_image = jsonParse(courseDetails?.course_image);
                    const external_pdf = jsonParse(courseDetails?.external_pdf);
                    const course_video = jsonParse(courseDetails?.course_video);
                    const course_pdf = jsonParse(courseDetails?.course_pdf);
                    if(course_image == null|| !course_image || course_image === "None"){
                        courseDetails.course_image = null;
                    }else{
                        courseDetails.course_image = JSON.parse(course_image);
                    }
                    if(external_pdf == null|| !external_pdf || external_pdf === "None"){
                        courseDetails.external_pdf = null;
                    }else{
                        courseDetails.external_pdf = JSON.parse(external_pdf);
                    }
                    if(course_video == null|| !course_video || course_video === "None"){
                        courseDetails.course_video = null;
                    }else{
                        courseDetails.course_video = JSON.parse(course_video);
                    }
                    if(course_pdf == null|| !course_pdf || course_pdf === "None"){
                        courseDetails.course_pdf = null;
                    }else{
                        courseDetails.course_pdf = JSON.parse(course_pdf);
                    }
                    if(action?.payload?.modules?.length>0){
                        const models = action?.payload?.modules?.filter((item)=>item?.id)?.map((item)=>{
                            const file = jsonParse(item?.file);
                            const image = jsonParse(item?.image);
                            const videos = item?.videos?.map((item)=>{
                                const course_image = JSON.parse(jsonParse(item?.video));
                                return{
                                    ...course_image,
                                    video_id: item?.id,
                                }
                            })
                            return{
                                ...item,
                                file: item?.file == null|| !item?.file || item?.file === "None" ? null : JSON.parse(file),
                                image: item?.image == null|| !item?.image || item?.image === "None" ? null : JSON.parse(image),
                                videos: videos
                            }
                        });
                        courseDetails.models = models
                    }else{
                        courseDetails.models = []
                    }
            }
            return{
                ...state,
                courseDetails: courseDetails
            }
        }
        case ActionTypes.SET_STORE_COURSES_LEVEL_LIST:{
            const payloadData = action.payload?.map((item)=>{
                return{
                    ...item,
                    label: item?.level,
                    value: item?.id,
                }
            })
            return{
                ...state,
                coursesLevelList:payloadData,
            }
        }
        case ActionTypes.SET_STORE_PACKAGE_LIST:{
            const payloadData = action.payload?.map((item)=>{
                return{
                    ...item,
                    // label: item?.level,
                    value: item?.id,
                }
            })
            return{
                ...state,
                packageList:payloadData,
            }
        }
        case ActionTypes.SET_STORE_USER_MANAGER_LIST:{
            const payloadData = action.payload?.data?.filter((item)=>item?.id)
            return{
                ...state,
                userManager: {
                    data: payloadData,
                    pagination: action.payload?.pagination
                },
            }
        }
        case ActionTypes.SET_STORE_LOGIN_FORM:{
            return{
                ...state,
                remember_me: action?.payload,
            }
        }
        case ActionTypes.SET_STORE_USERS_DETAILS:{
            return{
                ...state,
                usersDetails: action?.payload,
            }
        }
        case ActionTypes.SET_STORE_FAQ_LIST:{
            // console.log("FAQLIST", action?.payload);
            return{
                ...state,
                faqList:action?.payload
            }
        }
        case ActionTypes.SET_STORE_BLOG_LIST:{
            const payloadData = action.payload?.data?.filter((item)=>item?.id)?.map((item)=>{
                const image = jsonParse(item?.image);
                return{
                    ...item,
                    image: item?.image == null|| !item?.image || item?.image === "None" ? null : JSON.parse(image),
                }
        });
            return{
                ...state,
                blogList:{
                    data:payloadData,
                    pagination:action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_PRIVACY_POLICY_LIST:{
            // console.log("policylist", action?.payload);
            return{
                ...state,
                privacyPolicyList:action?.payload
            }
        }
        case ActionTypes.SET_STORE_TERMS_AND_CONDITIONS_LIST:{
            // console.log("terms", action?.payload);
            return{
                ...state,
                termsAndConditionsList:action?.payload
            }
        }
        case ActionTypes.SET_STORE_SALES_HISTORY_LIST:{
            return{
                ...state,
                salesHistory:action?.payload
            }
        }
        case ActionTypes.SET_STORE_DASHBOARD_COUNT:{
            return{
                ...state,
                dashboardStat:action?.payload ? action?.payload : state?.dashboardStat
            }
        }
        case ActionTypes.SET_STORE_HIGHEST_ASSIGN_COURSE_LIST:{
            return{
                ...state,
                heightAssignCourse:action?.payload ? action?.payload : state?.heightAssignCourse
            }
        }
        default:
            return state;
    }
};